import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  useRecordContext,
  ReferenceInput,
  SelectInput,
  required,
  useDataProvider
} from 'react-admin';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  pickupStatusSymbols,
  numberToEmoji
} from 'src/utils';
import { useFormContext } from "react-hook-form";
import { Chip } from '@mui/material';
import { availableProductStatus } from 'src/utils';

import styles from 'src/resources/Products/styles.module.scss';

const clothesDescriptionTemplate =
  "Αναγραφόμενο μέγεθος: </br>"
  + "Εκτιμώμενο μέγεθος: </br>"
  + "Σύνθεση: </br>"
  + "Άλλο: </br>"
  + "Κατάσταση: ";
const skirtsDescriptionTemplate =
  "Αναγραφόμενο μέγεθος: </br>"
  + "Εκτιμώμενο μέγεθος: </br>"
  + "Σύνθεση: </br>"
  + "Μέγεθος μέσης σε cm: </br>"
  + "Μήκος σε cm: </br>"
  + "Άλλο: </br>"
  + "Κατάσταση: ";
const jeansPantsDescriptionTemplate =
  "Αναγραφόμενο μέγεθος: </br>"
  + "Εκτιμώμενο μέγεθος: </br>"
  + "Σύνθεση: </br>"
  + "Μέγεθος μέσης σε cm: </br>"
  + "Μήκος απ΄τον καβάλο μέχρι την μέση σε cm: </br>"
  + "Μήκος απ' τον καβάλο μέχρι το τελείωμα σε cm: </br>"
  + "Άλλο: </br>"
  + "Κατάσταση: ";
const booksDescriptionTemplate =
  "Τύπος εξωφύλλου: </br>"
  + "Σελίδες: </br>"
  + "Περιγραφή: </br>"
  + "Άλλο: </br>"
  + "Κατάσταση: </br>";
const bagsDescriptionTemplate =
  "Διαστάσεις: </br>"
  + "Σύνθεση: </br>"
  + "Άλλο: </br>"
  + "Κατάσταση: </br>";
const beltsDescriptionTemplate =
  "Αναγραφόμενο μέγεθος: </br>"
  + "Μήκος σε cm: </br>"
  + "Φάρδος σε cm: </br>"
  + "Σύνθεση: </br>"
  + "Κατάσταση: </br>";

const getStatusPic = (rec) => {
  const status = pickupStatusSymbols.find(status => status?.id === rec?.bagPickupStatus?.bagPickupStatusName);

  return status ? status.pic + ' ' : '';
}

export const DescriptionOptions = () => {
  return (
    <Fragment>
      <div className={styles.title}>✍️ Item description:</div>
      <div>
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = clothesDescriptionTemplate;
          }}
          label='👚 Clothes' />/
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = skirtsDescriptionTemplate;
          }}
          label='👗 Skirts' />/
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = jeansPantsDescriptionTemplate;
          }}
          label='👖 Jeans & Pants' />/
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = booksDescriptionTemplate;
          }}
          label='📚 Books' />/
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = bagsDescriptionTemplate;
          }}
          label='👜 Bags' />/
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = beltsDescriptionTemplate;
          }}
          label='🥋 Belts' />/
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = '';
          }}
          label='🗑️ Empty' />
      </div>
      <div className={styles.diviver} />
      <div className={styles.title}>🆕 Condition:</div>
      <div>
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Κατάσταση: ", "Κατάσταση: Καινούριο - Με το ταμπελάκι");
          }}
          label='New 🏷️' />/
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Κατάσταση: ", "Κατάσταση: Εξαιρετική");
          }}
          label='5 ⭐' />/
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Κατάσταση: ", "Κατάσταση: Πολύ καλή");
          }}
          label='4 ⭐' />
      </div>
      <div className={styles.diviver} />
      <div className={styles.title}>🆕 Clothes:</div>
      <div>
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Άλλο: ", "Άλλο: Κομμάτι μοναδικής σχεδίασης, κατασκευασμένο από άριστης ποιότητας αυθεντικά και ανθεκτικά υλικά.");
          }}
          label='Special 💎' /> /
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Άλλο: ", "Άλλο: Vintage κομμάτι, κατασκευασμένο από άριστης ποιότητας αυθεντικά και ανθεκτικά υλικά.");
          }}
          label='Vintage 🕰' /> /
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Άλλο: ", "Άλλο: Κομμάτι Y2K, δημιουργήθηκε στα τέλη της δεκαετίας του '90-αρχές 2000 και επανήλθε στη μόδα λόγω του συνδυασμού εκκεντρικότητας και κομψότητας.");
          }}
          label='Y2K 💋ྀིྀི' />
      </div>
      <div>
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Άλλο: ", "Άλλο: Με φερμουάρ πίσω. ");
          }}
          label='Zipper Back 🤐' /> /
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Άλλο: ", "Άλλο: Με φερμουάρ στο πλάι. ");
          }}
          label='Zipper Side 🤐' />
      </div>
      <div className={styles.diviver} />
      <div className={styles.title}>🧶 Material Composition:</div>
      <div>
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Εκτιμώμενη σύνθεση: ", "Σύνθεση: ",);
          }}
          label='On label: 🏷️' />/
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Σύνθεση: ", "Εκτιμώμενη σύνθεση:");
          }}
          label='Estimated: 🤔' />
      </div>
      <div className={styles.diviver} />
      <div className={styles.title}>📚 Books:</div>
      <div>
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Τύπος εξωφύλλου: ", "Τύπος εξωφύλλου: Hardcover",);
          }}
          label='Hardcover: 📕' />/
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Τύπος εξωφύλλου: ", "Τύπος εξωφύλλου: Softcover");
          }}
          label='Softcover: 📃' />
      </div>
      <div>
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML.replace("Άλλο: ", "Άλλο: Εξαντλημένο από τον εκδότη");
          }}
          label='Publisher Sold-out 💸' />
      </div>
      <div className={styles.diviver} />
      <div className={styles.title}>💬 Other:</div>
      <div>
        <Button
          className={styles.buttonText}
          onClick={() => {
            const node = document.getElementById('itemDescription');
            node.innerHTML = node.innerHTML += "Το μοντέλο έχει ύψος 1.68cm και 58 κιλά. Φοράει συνήθως νούμερο S";
          }}
          label='Model dimensions 💃📐' />
      </div>
    </Fragment>
  );
}

export const CopyProductSKU = () => {
  const record = useRecordContext();

  return (
    <Button
      style={{ minWidth: '32px' }}
      sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }}}
      onClick={() => navigator.clipboard.writeText(record?.code)}
    >
      <ContentCopyIcon />
    </Button>
  );
}

export const generateSlug = (name, setSlug) => {
  let slug;
  const replace = ['α', 'ά', 'β', 'γ', 'δ', 'ε', 'έ', 'ζ', 'η', 'ή', 'θ', 'ι', 'ί', 'κ', 'λ', 'μ', 'ν', 'ξ', 'ο', 'ό', 'π', 'ρ', 'σ', 'τ', 'υ', 'ύ', 'φ', 'χ', 'ψ', 'ω', 'ώ', 'ς'];
  const replace_n = ['a', 'a', 'b', 'g', 'd', 'e', 'e', 'z', 'i', 'i', 'th', 'i', 'i', 'k', 'l', 'm', 'n', 'ks', 'o', 'o', 'p', 'r', 's', 't', 'u', 'u', 'f', 'ch', 'ps', 'o', 'ο', 's'];
  for (var i = 0; i < replace.length; i++) {
    name = name.toLowerCase().replaceAll(replace[i], replace_n[i]);
  }
  slug = name.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
  setSlug(slug);
};

export const LowValueDescriptionButton = () => {
  const { setValue, getValues } = useFormContext();

  return (
    <Button
      onClick={() => {
        setValue("name", getValues("name") + ' χαμηλής μεταπωλητικής αξίας');
      }}
      label='📉 Low Value' />
  );
}

export const GetUser = ({ rec, ...props }) => {
  const dataProvider = useDataProvider();
  const [userName, setUserName] = useState('Loading User...');

  useEffect(() => {
    (async (rec) => {
      let res = '';
      try {
        res = await dataProvider.getOne('users', { id: rec?.userAddressSnapshot?.userId });
      } catch (error) {
        console.warn('Error: ', error);
      }
      setUserName((userName) => {
        return res?.data?.firstName + ' ' + res?.data?.lastName;
      })
    })(rec)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rec])
  if (rec?.id?.toString() === '@@ra-create') {
    return ('🔍  Search for older Pickup Id 🔍');
  }
  return (rec?.id?.toString() + " " + numberToEmoji[rec?.numberOfBagsToReturn] + ' (' + getStatusPic(rec) + rec?.bagPickupStatus?.bagPickupStatusName + ') - ' + userName);
}


export const PickupIdSelector = () => {
  return (
    <ReferenceInput
      label="Pickup ID"
      source="bagPickupId"
      reference="pickups"
      perPage={100}
      alwaysOn
    >
      <SelectInput
        optionText={rec => rec?.id && <GetUser rec={rec} />}
        fullWidth
        resettable
        validate={[required()]}
        onCreate={() => {
          const pickupName = prompt('Enter a Pickup ID to search:');
          const pickup = { id: pickupName?.toLowerCase(), name: pickupName };
          return pickup;
        }}
      />
    </ReferenceInput>
  );
};

export const renderStatusChip = (record) => {
  if (!record) {
    return null;
  }

  const value = record.productStatusName;
  let chipProps = {
    label: availableProductStatus.find(status => status?.id === value)?.name || value,
    size: "large", // Default size, can be customized
  };

  return <Chip {...chipProps} />;
};
