import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  DateField,
  Edit,
  FormTab,
  FunctionField,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  SimpleShowLayout,
  TabbedForm,
  TextField,
  TextInput,
  useCreatePath,
  useDataProvider,
  usePermissions,
  useRecordContext
} from 'react-admin';
import { TextField as MUITextField, Button as MUIButton } from '@mui/material';
import { Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';

import { BackgroundImageField } from 'src/utils';
import { renderStatusChip } from 'src/resources/Products/common';
import { userRoles, countryCodes, availableLanguages, VerifyEmail } from "src/utils/index";
import { CopyUserEmail, CopyUserId, GetBankName, GetUsersPickups, GetUsersOrders, GetAvailableBags } from "../utils";

import ContactMailIcon from '@mui/icons-material/ContactMail';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WorkIcon from '@mui/icons-material/Work';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const CreateBagOrder = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [isCreateDisabled, setIsCreateDisabled] = useState(false);

  const createBagOrder = () => {
    try {
      const numberOfBags = document.getElementById("numberOfBagsToOrder");
      const params = {
        data: {
          id: record.id,
          numberOfBags: numberOfBags.value
        }
      };
      dataProvider.create('bagorders', params);
      numberOfBags.value = '';
      setIsCreateDisabled(true);
    } catch (error) {
      console.warn('Error: ', error);
    }
  }

  return (
    <Fragment>
      <div>
        <MUITextField
          id='numberOfBagsToOrder'
          type="number"
          defaultValue={1}
          InputProps={{ inputProps: { max: 10, min: 0 }}}
          label="No of bags to Order"
          onChange={() => setIsCreateDisabled(document.getElementById("numberOfBagsToOrder") === 0)}
        />
      </div>
      <MUIButton variant="contained" disabled={isCreateDisabled} onClick={() => {
        if (window.confirm('⚠️ Are you sure you want to create this Bag Order? This action can not be undone!')) {
          createBagOrder();
        } else {
          // Do nothing!
        }}}>
        Create Bag Order
      </MUIButton>
    </Fragment>
  )
}

const CreatePickup = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [isCreateDisabled, setIsCreateDisabled] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const createPickup = () => {
    try {
      const numberOfBags = document.getElementById("numberOfBagsToPickup");
      const params = {
        data: {
          uuid: record.id,
          numberOfBagsToReturn: Number(numberOfBags.value)
        }
      };
      dataProvider.create('pickups', params);
      numberOfBags.value = '';
      setIsCreateDisabled(true);
    } catch (error) {
      console.warn('Error: ', error);
    }
  }

  return (
    <Fragment>
      <div>
        <MUITextField
          id='numberOfBagsToPickup'
          type="number"
          defaultValue={1}
          InputProps={{ inputProps: { max: 10, min: 0 }}}
          label="No of bags to Pickup"
          onChange={() => setIsCreateDisabled(document.getElementById("numberOfBagsToPickup") === 0)}
        />
      </div>
      <MUIButton variant="contained" disabled={isCreateDisabled} onClick={() => {
        if (window.confirm('⚠️ Are you sure you want to create this Bag Pickup? This action can not be undone!')) {
          createPickup();
        } else {
          // Do nothing!
        }}}>
        Create Pickup
      </MUIButton>
    </Fragment>
  )
}

export const GoToProduct = ({ id }) => {
  const createPath = useCreatePath();
  const productPath = createPath({ resource: 'products', type: 'edit', id });

  return (
    <Fragment>
      <MUIButton
        component={Link}
        variant="contained"
        to={productPath}
      >
        Edit
      </MUIButton>
    </Fragment>
  );
};

const GetUserFavorites = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [favorites, setFavorites] = useState([]);
  const [productDetails, setProductDetails] = useState({});

  useEffect(() => {
    const fetchFavoritesAndProducts = async () => {
      try {
        // Fetch favorites
        const { data: favoritesData } = await dataProvider.getList(`favorites/user/${record.id}`, { id: record.id });
        setFavorites(favoritesData);

        // Fetch all product details in parallel
        if (favoritesData.length > 0) {
          const productPromises = favoritesData.map(favorite =>
            dataProvider.getOne('products', { id: favorite.productUuid })
          );

          const productsResults = await Promise.all(productPromises);
          // Create a map of product details
          const detailsMap = {};
          productsResults.forEach((result, index) => {
            detailsMap[favoritesData[index].productUuid] = result.data;
          });
          setProductDetails(detailsMap);
        }
      } catch (error) {
        console.warn('Error fetching data:', error);
        setFavorites([]);
        setProductDetails({});
      }
    };

    if (record?.id) {
      fetchFavoritesAndProducts();
    }
  }, [dataProvider, record?.id]);

  return (
      favorites.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'black' }}>#</TableCell>
              <TableCell sx={{ color: 'black' }}>SKU</TableCell>
              <TableCell sx={{ color: 'black' }}>Image</TableCell>
              <TableCell sx={{ color: 'black' }}>Product Name</TableCell>
              <TableCell sx={{ color: 'black' }}>Edit</TableCell>
              <TableCell sx={{ color: 'black' }}>Price</TableCell>
              <TableCell sx={{ color: 'black' }}>Sale Price</TableCell>
              <TableCell sx={{ color: 'black' }}>Status</TableCell>
              <TableCell sx={{ color: 'black' }}>In-Store</TableCell>
              <TableCell sx={{ color: 'black' }}>Storage Position</TableCell>
              <TableCell sx={{ color: 'black' }}>Date Added</TableCell>
              <TableCell sx={{ color: 'black' }}>Date Sold</TableCell>
              <TableCell sx={{ color: 'black' }}>User Note</TableCell>
              <TableCell sx={{ color: 'black' }}>Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {favorites.map((favorite, index) => (
              <TableRow key={favorite.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{favorite.productUuid.substring(0, 8)}</TableCell>
                <TableCell><BackgroundImageField favorite={favorite.productUuid} label="Image" /></TableCell>
                <TableCell>{productDetails[favorite.productUuid]?.name || 'Loading...'}</TableCell>
                <TableCell><GoToProduct id={favorite.productUuid} /></TableCell>
                <TableCell>{productDetails[favorite.productUuid]?.price + "€" || 'Loading...'}</TableCell>
                <TableCell>{productDetails[favorite.productUuid]?.salePrice + "€" || 'Loading...'}</TableCell>
                <TableCell>{renderStatusChip(productDetails[favorite.productUuid])}</TableCell>
                <TableCell>{productDetails[favorite.productUuid]?.inStoreDisplay ? "✅" : "❌" }</TableCell>
                <TableCell>{productDetails[favorite.productUuid]?.storagePosition || "❌" }</TableCell>
                <TableCell>{new Date(favorite.dateAdded).toLocaleString()}</TableCell>
                <TableCell>{productDetails[favorite.productUuid]?.soldAt ? new Date(productDetails[favorite.productUuid]?.soldAt).toLocaleString() : "-"}</TableCell>
                <TableCell>{favorite.userNote || 'No note'}</TableCell>
                <TableCell>
                  <MUIButton color="error" variant="contained" onClick={() => {
                    dataProvider.delete('favorites', { productUuid: favorite.productUuid, userUuid: record.id })
                    .then(() => {
                      // Trigger refetch of the record
                      setFavorites(favorites.filter(f => f.productUuid !== favorite.productUuid));
                    });
                  }}>
                    Remove
                  </MUIButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="body1" align="center">
          No favorites found
        </Typography>
      )
  );
};

const UserEdit = props => {
  const { permissions } = usePermissions();

  return (
    <Edit {...props}>
      <TabbedForm warnWhenUnsavedChanges redirect={false}>
        <FormTab label="Basic" icon={<ContactMailIcon />}>
          <SimpleShowLayout>
            <FunctionField
              label="User ID"
              render={record => {
                return (
                  <Fragment>
                    <TextField id='userUniqueId' source="id" fullWidth  />
                    <CopyUserId />
                  </Fragment>
                )
              }}
            />
            <FunctionField
              render={record => {
                return (
                  <Fragment>
                    <div>
                      <TextField source="email" />
                      <CopyUserEmail />
                    </div>
                  </Fragment>
                )}}
              label="Email"
            />
            <BooleanField source="emailVerified" />
            <VerifyEmail />
            <TextInput source="firstName" />
            <TextInput source="lastName" />
            <SelectInput label="Country code" source="countryCode" choices={countryCodes} />
            <TextInput source="phoneNumber" />
            <SelectInput label="Language" source="language" choices={availableLanguages} />
            <SelectArrayInput label="Roles" source="roles" choices={userRoles} />
          </SimpleShowLayout>
        </FormTab>
        {permissions === 'SUPER_ADMIN' && (
          <FormTab label="Addresses" icon={<HomeWorkIcon />}>
            <SimpleShowLayout>
              <ArrayInput source="addresses">
                <SimpleFormIterator>
                  <TextInput source="addressType" label="Address name" />
                  <TextInput source="street" label="Street name" />
                  <TextInput source="streetNumber" label="Street number" />
                  <TextInput source="city" label="City" />
                  <TextInput source="postcode" label="Postcode" />
                  <TextInput source="country" label="Country" />
                  <SelectInput label="Country code" source="countryCode" choices={countryCodes} />
                  <TextInput source="phoneNumber" label="Phone number" />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleShowLayout>
          </FormTab>
        )}
        {permissions === 'SUPER_ADMIN' && (
          <FormTab label="Payments" icon={<AttachMoneyIcon />}>
            <SimpleShowLayout>
              <TextInput source="iban" />
              <GetBankName />
              <TextInput source="vatRegistrationNumber" />
            </SimpleShowLayout>
          </FormTab>
        )}
        <FormTab label="Pickups" icon={<WorkIcon />}>
          <SimpleShowLayout>
            <GetUsersPickups />
          </SimpleShowLayout>
        </FormTab>
        {permissions === 'SUPER_ADMIN' && (
          <FormTab label="Orders" icon={<ReceiptIcon />}>
            <SimpleShowLayout>
              <GetUsersOrders />
            </SimpleShowLayout>
          </FormTab>
        )}
        {permissions === 'SUPER_ADMIN' && (
          <FormTab label="Sales" icon={<PointOfSaleIcon />}>
            <SimpleShowLayout>
              <div>Coming Soon!</div>
            </SimpleShowLayout>
          </FormTab>
        )}
        {permissions === 'SUPER_ADMIN' && (
          <FormTab label="Favorites" icon={<FavoriteIcon />}>
            <SimpleShowLayout>
              <GetUserFavorites />
            </SimpleShowLayout>
          </FormTab>
        )}
        {permissions === 'SUPER_ADMIN' && (
          <FormTab label="Shopping Cart" icon={<ShoppingCartIcon />}>
            <SimpleShowLayout>
              <div>Coming Soon!</div>
            </SimpleShowLayout>
          </FormTab>
        )}
        {permissions === 'SUPER_ADMIN' && (
        <FormTab label="Bags" icon={<MedicalServicesIcon />}>
          <SimpleShowLayout>
            <GetAvailableBags />
            <CreateBagOrder />
            <CreatePickup />
          </SimpleShowLayout>
        </FormTab>
        )}
        {permissions === 'SUPER_ADMIN' && (
          <FormTab label="Admin" icon={<AdminPanelSettingsIcon />}>
            <SimpleShowLayout>
              <h3>Communication Preferences:</h3>
              <BooleanInput label="Newsletter Emails" source="userInfo.allowNewsletterEmail" />
              <BooleanInput label="Phone Calls" source="userInfo.allowNewsletterPhone" />
              <BooleanInput label="SMS Messages" source="userInfo.allowNewsletterSms" />
              <BooleanInput label="Letters by Post" source="userInfo.allowNewsletterPost" />
              <BooleanInput label="Website Cookies" source="userInfo.allowCookies" />
              <hr />
              <DateField label='Last Login Date:' source='lastLoginDate' locales="el-GR" showTime/>
              <DateField label='Account Creation:' source="createdAt" locales="el-GR" showTime />
            </SimpleShowLayout>
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export default UserEdit;
