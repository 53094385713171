import React from 'react';
import {
  TextInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  DateTimeInput,
  required
} from 'react-admin';
import { useFormContext } from "react-hook-form";

const requiredFields = [
  "clotheRecommendedSize",
  "condition",
  "clotheColor",
  "language",
  "pages",
  "season",
  "cover"
];

const subcategoriesThatRequireSleeveType = [
  1, // Shirts
  2, // Jackets and Blazers
  4, // Dresses
  5, // Pullovers and Hoodies
  39, // Tops
  40, // Cardigans
  63, // Jumpsuits
];

const sleeveTypeSubcategory = (element) => subcategoriesThatRequireSleeveType.includes(element);

export const IsAttributeRequired = (attribute) => {
  const record = useFormContext();
  const productStatus = record.getValues().productStatusName;
  const productSubcategories = record.getValues().subcategoriesIds;
  const isProductForSale = productStatus === "UNDER_SELL";

  if (!isProductForSale) {
    // Do not require attributes, until product is listed for sale:
    return false;
  }
  if (isProductForSale && attribute.value === "publishedAt") {
    // Require publishedAt, if product is listed for sale:
    return true;
  }
  if (attribute.value === "sleeveType" && productSubcategories.some(sleeveTypeSubcategory)) {
    // Require sleeveType for special cases;
    return true;
  }
  if (requiredFields.includes(attribute.value)) {
    // Generally required attributes:
    return true;
  }
  return false;
}

export default function ProductAttributes({ options }) {

  return (
    <div className='single-category'>
      <div className="category-options">
        {options.map((filter, index) => {
          let element = '';
          if (filter.type === 'options') {
            element = (
              <SelectInput
                label={filter.title}
                source={filter.value}
                choices={filter.options}
                validate={[IsAttributeRequired(filter) && required()]}
              />
            )
          } else if (filter.type === 'boolean') {
            element = <BooleanInput label={filter.title} source={filter.value} />
          } else if (filter.type === 'string') {
            element = <TextInput label={filter.title} source={filter.value} />
          } else if (filter.type === 'datetime') {
            element = <DateTimeInput label={filter.title} source={filter.value} locales="el-GR" />
          } else if (filter.type === 'number') {
            element = <NumberInput label={filter.title} source={filter.value} validate={[IsAttributeRequired(filter) && required()]}/>
          }

          return (
            <div key={index}>
              {element}
            </div>
          );
        })}
      </div>
    </div>
  )
}