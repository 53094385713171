import { getHeaders, adminUrl, adminPublicUrl, authUrl, paymentUrl, getNewPhotos, s3Url } from '../utils';
import { accessoriesAttributes, bookAttributes, clothesAttributes, productAttributes } from '../utils/productAttributes';

const formatData = (resource, response) => {
  let formattedData = response?.data || response;
  if (resource === 'translations') {
    if (formattedData.key) {
      formattedData.id = formattedData.key+formattedData.language;
    } else {
      formattedData = formattedData.map(d => ({ id: d.key+d.language, ...d }))
    }
  }
  if (resource === 'promotional-codes') {
    formattedData = response.promotionalCodes;
  }
  return formattedData;
}

const getUrlForResource = resource => {
  if (resource === 'users') return authUrl;
  if (resource === 'orders' || resource === "payment-methods" || resource === "shipments/types" || resource === "promotional-codes") return paymentUrl;
  return adminUrl;
}

const specialCases = async (resource, params, data) => {

  if (resource === 'pickups' && params && params.id) {
    let url = getUrlForResource(resource);
    url = `${url}/pickups/${params.data.id}/status/${params.data.bagPickupStatus.bagPickupStatusName}`;
    await fetch(url, {
      method: 'PUT',
      headers: getHeaders(),
    }).then(res => res.json());
  }

  if (resource === 'users') {
    let url = getUrlForResource(resource);
    url = `${url}/${resource}/role/${params.id}`;
    await fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ roles: params.data.roles, id: parseInt(params.id) }),
      headers: getHeaders(),
    }).then(res => res.json());
  }
  if (resource === 'orders' && params && params.id) {
    let url = getUrlForResource(resource);
    url = `${url}/orders/${params.data.id}/status/${params.data.orderStatus.orderStatusName}`;
    await fetch(url, {
      method: 'PUT',
      headers: getHeaders(),
    }).then(res => res.json());
  }

  if (resource === 'bags') {
    let url = getUrlForResource(resource);
    url = `${url}/bags/1`;
    await fetch(url, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify({
        price: params.data.price,
        bagsUpdate: params.data.bagsUpdate,
        updateTotal: params.data.updateAvailable ? false : true,
      }),
    }).then(res => res.json());
  }

  if (resource === 'bagorders') {
    let url = getUrlForResource('orders');
    url = `${url}/orders/user/${params.data.id}/bags/${params.data.numberOfBags}`;
    await fetch(url, {
      method: 'POST',
      headers: getHeaders(),
    }).then(res => res.json());
  }

  if (resource === 'products' && data.images) {
    var images = new FormData();
    for (const image of data.images) {
      if (image.rawFile) {
        await images.append('files', image.rawFile);
        // console.log('Successfull append new image', image.rawFile);
      } else {
        try {
          let imageName = image.data.split('/');
          await images.append('deleteExclusion', imageName[imageName.length-1]);
        } catch(err) {
          console.log('Failed to append image:', image, err);
        }
      }
    };

    await fetch(`${adminUrl}/${resource}/${params.data.id}/images/upload/new`, {
      method: 'POST',
      headers: getHeaders('none'),
      body: images,
    }).catch(err => console.log('Failed to save images', images, data.images, err));
  }

  if ((resource === 'categories' || resource === 'subcategories') && data.images) {
    const image = data.images;
    images = new FormData();
      if (image.rawFile) {
        await images.append('file', image.rawFile);
        // console.log('Successfull append new image', image.rawFile);
      } else {
        try {
          let imageName = image.data.split('/');
          await images.append('deleteExclusion', imageName[imageName.length-1]);
        } catch(err) {
          console.log('Failed to append image:', image, err);
        }
    };

    await fetch(`${adminUrl}/${resource}/${params.data.uuid}/images/upload`, {
      method: 'POST',
      headers: getHeaders('none'),
      body: images,
    }).catch(err => console.log('Failed to save images', images, data.images, err));
  }

  if (resource === 'translations') {
    let url = getUrlForResource(resource);
    await fetch(`${url}/${resource}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify({
        key: params.data.key,
        language: params.data.language,
        value: params.data.value,
      }),
    }).then(res => res.json());
  }
}

const deleteTranslation = async (resource, params) => {
  const { language, key } = params.previousData;
    let url = getUrlForResource(resource);
    await fetch(`${url}/${resource}/language/${language}/key/${key}`, {
      method: 'DELETE',
      headers: getHeaders(),
    }).then(res => res.json());
}

const deleteFavorite = async (resource, params) => {
  const { productUuid, userUuid } = params;
  let url = getUrlForResource(resource);
  await fetch(`${url}/${resource}/user/${userUuid}/product/${productUuid}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });
}

const nullIfEmpty = function (data) {
  return data === '' ? null : data;
}

const dataProvider = {
  getList: (resource, params) => {
    const { filter, pagination, sort } = params;
    let url = getUrlForResource(resource);
    return new Promise(async (resolve,reject) => {
      if (pagination && sort) {
        url = `${url}/${resource}?page=${pagination.page}&size=${pagination.perPage}&sort=${sort.field},${sort.order.toLowerCase()}`;
      } else if (pagination && !sort) {
        url = `${url}/${resource}?page=${pagination.page}&size=${pagination.perPage}`;
      } else if (!pagination && sort) {
        url = `${url}/${resource}?sort=${sort.field},${sort.order.toLowerCase()}`;
      } else {
        url = `${url}/${resource}`;
      }
      let filterKeys = [];
      if (filter) {
        filterKeys = Object.keys(filter);
      }
      if (filterKeys.length) {
        filterKeys.forEach(key => {
          // console.log(key);
          // console.log(filter);
          //Handle nested filter for addresses.postcode

          if (!filter[key]) {
            return; // Avoid sending requests with undefined filters
          }

          if (key==='name') {
            url = url + `&name=${encodeURIComponent(filter[key])}`;
          } else if (key==='roles') {
            url = url + `&roles.role=${filter[key]}`;
          } else if (key==='bagPickupStatus') {
            url = url + `&bagPickupStatus.bagPickupStatusName=${filter[key].bagPickupStatusName}`;
          } else if (key==='id' && (filter[key].language || filter[key].key)) {
            if (filter[key].language) {
              url = url + `&${key}.${Object.keys(filter[key])[0]}=${filter[key].language}`;
            }
            if (filter[key].key) {
              url = url + `&${key}.${Object.keys(filter[key])[0]}=${filter[key].key}`;
            }
          } else if (key === 'orderStatus') {
            url = url + `&${key}.${Object.keys(filter[key])[0]}=${filter[key].orderStatusName}`;
          } else if (key === "gender") {
            url = url + `&productInfo.gender=${filter.gender}`;
          } else if (key === 'userAddressSnapshot') {
            url = url + `&${key}.${Object.keys(filter[key])[0]}=${filter[key].phoneNumber}`;
          } else if (key === 'subcategoriesIds') {
              url = url + `&subcategories.id=${filter?.subcategoriesIds}`;
          } else if (productAttributes.map(a => a.value).includes(key)) {
              url = url + `&productFilters.${key}=${nullIfEmpty(filter[key])}`;
          } else if (bookAttributes.map(a => a.value).includes(key)) {
              url = url + `&productBookFilters.${key}=${nullIfEmpty(filter[key])}`;
          } else if (accessoriesAttributes.map(a => a.value).includes(key)) {
              url = url + `&productAccessoriesFilters.${key}=${nullIfEmpty(filter[key])}`;
          } else if (clothesAttributes.map(a => a.value).includes(key)) {
              url = url + `&productClothesFilters.${key}=${nullIfEmpty(filter[key])}`;
          } else if (key === 'addresses') {
            url = url + `&addresses.postcode=${filter[key].postcode}`;
          // Cases for range filtering:
          } else if (key.includes('_range_')) {
            const theKey = key.split('_range_');
            if (bookAttributes.map(a => a.value).includes(theKey[0])) {
              url = url + `&productBookFilters.${theKey[0]}=${nullIfEmpty(filter[key])}`;
            } else if (accessoriesAttributes.map(a => a.value).includes(theKey[0])) {
              url = url + `&productAccessoriesFilters.${theKey[0]}=${nullIfEmpty(filter[key])}`;
            } else if (clothesAttributes.map(a => a.value).includes(theKey[0])) {
              url = url + `&productClothesFilters.${theKey[0]}=${nullIfEmpty(filter[key])}`;
            } else if (productAttributes.map(a => a.value).includes(theKey[0])) {
              url = url + `&productFilters.${theKey[0]}=${nullIfEmpty(filter[key])}`;
            } else {
              url = url + `&${theKey[0]}=${nullIfEmpty(filter[key])}`;
            }
          // End cases for range filtering!
          } else {
            if (key === 'orderTypeName') {
              url = url + `&orderType.orderTypeName=${filter[key]}`;
            } else {
              url = url + `&${key}=${filter[key]}`;
            }
          }
        })
      }
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: getHeaders(),
        }).then(res => res.json())
        resolve({ data: formatData(resource, response), total: response?.total || formatData(resource, response)?.data?.length})
      } catch (err) {
        console.log('Got error:', err);
        reject(err);
      }
    })
  },
  getOne: (resource, params) => {
    const { id } = params;
    if (!id) {
      return Promise.resolve({ data: { id: undefined } });
    }
    let url = getUrlForResource(resource);
    return new Promise(async (resolve,reject) => {
      if (resource === 'translations') {
        const language = id.slice(id.length - 2);
        const key =  id.slice(0, id.length - 2);
        url = `${url}/${resource}/language/${language}/key/${key}`;
      } else {
        url = `${url}/${resource}/${id}`;
      }
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: getHeaders(),
        }).then(res => res.json());

        if (resource === 'products') {
          const images = await fetch(`${adminUrl}/products/${id}/images`, {
            method: 'GET',
            headers: getHeaders(),
          }).then(res => res.json());
          response.data.images = images.data?.map(val => ({ data: `${s3Url(resource)}/${val}` }));
        }
        if (resource === 'categories' || resource === 'subcategories') {
          let images;
          try {
            images = await fetch(`${adminUrl}/${resource}/${response.data.uuid}/images`, {
              method: 'GET',
              headers: getHeaders(),
            }).then(res => res.text())
          } catch (err) {
            console.log("Got error: ", err);
          }
          response.data.images = images ? [{ data: `${s3Url(resource)}/${images}` }] : null;
        }
        if (resource === "promotional-codes") {
          resolve({ data: response });
        }
        resolve({ data: formatData(resource, response) })
      } catch (err) {
        reject(err);
      }
    })
  },
  getMany: (resource, params) => {
    let { ids } = params;
    ids = ids.filter(id => Array.isArray(id) ? id.length > 0 : id);
    let url = getUrlForResource(resource);
    if (resource === 'shipmentTimes') {
      resource = 'shipments/time';
    }
    return new Promise(async (resolve,reject) => {
      url = `${url}/${resource}?filter=${ids.reduce((acc, curr) => `${acc},${curr}`)}`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: getHeaders(),
        }).then(res => res.json());
        resolve({ data: formatData(resource, response.data), total: response.total })
      } catch (err) {
        console.log('Got error:', err);
        reject(err);
      }
    })
  },
  getManyReference: (resource, params) => {
    console.log('getManyReference WAS USED! Needs implementation', resource, params);
  },
  create: async (resource, params) => {
    let { data } = params;
    const copyOfData = {...data};
    if (resource === 'products') {
      delete data.images;
    }
    let url = getUrlForResource(resource);
    return new Promise(async (resolve,reject) => {
      url = `${url}/${resource}`;
      try {
        await specialCases(resource, params, copyOfData);
        let response = "";
        if (resource !== 'bagorders') {
          response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: getHeaders(),
          }).then(res => res.json());
        }
        resolve({ data: formatData(resource, response) })
      } catch (err) {
        reject(err);
      }
    })
  },
  update: async (resource, params) => {
    console.log('update WAS USED!', resource, params);
    let { id, data } = params;
    data = await getNewPhotos(data);
    const copyOfData = {...data};
    if ((resource === 'orders' && params && params.id) || (resource === 'pickups' && params?.id) || resource === 'bags' || resource === 'translations') {
      return specialCases(resource, params);
    }
    if (resource === 'products' || resource === 'categories' || resource === 'subcategories') {
      //if (data?.images && data?.images?.length === 0) {
        delete data.images;
      // }
    }
    Object.keys(data).forEach(key => {
      data[key] = nullIfEmpty(data[key]);
    });
    let url = getUrlForResource(resource);
    return new Promise(async (resolve,reject) => {
      url = `${url}/${resource}/${id}`;
      try {
        await specialCases(resource, params, copyOfData)
        const response = await fetch(url, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: getHeaders(),
        }).then(res => res.json());
        resolve({ data: formatData(resource, response.data), total: response.total })
      } catch (err) {
        reject(err);
      }
    })
  },
  updateMany: async (resource, updatedRecords) => {
    if (!Array.isArray(updatedRecords)) {
      return { data: [] }; // Return an empty array to match the expected structure
    }

    let baseUrl = getUrlForResource(resource);
    // Use Promise.all to wait for all requests to complete
    const updatePromises = updatedRecords.map(record => {
      const url = `${baseUrl}/${resource}/${record.id}`; // Use record id to update each record
      return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(record),
        headers: getHeaders(),
      })
      .then(res => res.json())
      .then(response => {
        if (!response.data) {
          throw new Error(`Response for record ID ${record.id} does not contain data`);
        }
        return formatData(resource, response.data); // Format the data
      })
      .catch(error => {
        console.error("Error updating ID: ", record.id, error);
        throw error;
      });
    });

    try {
      const results = await Promise.all(updatePromises);
      return { data: results }; // Return the results in an object with a 'data' key
    } catch (error) {
      console.error("Error in updateMany:", error);
      throw error;
    }
  },
  delete: (resource, params) => {
    if (resource === 'translations') {
      return deleteTranslation(resource, params);
    }
    if (resource === 'favorites') {
      return deleteFavorite(resource, params);
    }
    const { id } = params;
    let url = getUrlForResource(resource);
    return new Promise(async (resolve,reject) => {
      url = `${url}/${resource}/${id}`;
      try {
        const response = await fetch(url, {
          method: 'DELETE',
          headers: getHeaders(),
        }).then(res => res.json());
        resolve({ data: response })
      } catch (err) {
        reject(err);
      }
    })
  },
  deleteMany: (resource, params) => {
    console.log('deleteMany WAS USED! Needs implementation');
  },
  getPickups: async (resource, params) => {
    return new Promise(async (resolve, reject) => {
      let url = `${adminUrl}/pickups`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: getHeaders(),
        })
        .then(res => res.json());
        resolve({ data: response.data });
      } catch (err) {
        reject(err);
      }
    })
  },
  getPickupsNo: async (resource, params) => {
    return new Promise(async (resolve, reject) => {
      let url = `${adminUrl}/pickups?page=1&size=1&sort=createdAt,desc&bagPickupStatus.bagPickupStatusName=PICKED_UP`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: getHeaders(),
        })
        .then(res => res.json());
        resolve({ total: response.total });
      } catch (err) {
        reject(err);
      }
    })
  },
  getUserPickups: async (userId) => {
    return new Promise(async (resolve, reject) => {
      let url = `${adminUrl}/pickups/user/${userId}`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: getHeaders(),
        })
        .then(res => res.json());
        resolve({ data: response.data });
      } catch (err) {
        reject(err);
      }
    })
  },
  getUserOrders: async (userId) => {
    return new Promise(async (resolve, reject) => {
      let url = `${paymentUrl}/orders/user/${userId}`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: getHeaders(),
        })
        .then(res => res.json());
        resolve({ data: response.data });
      } catch (err) {
        reject(err);
      }
    })
  },

  getUserBags: async (userId) => {
    return new Promise(async (resolve, reject) => {
      let url = `${adminUrl}/bags/user/${userId}`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: getHeaders(),
        })
        .then(res => res.json());
        resolve(response);
      } catch (err) {
        reject(err);
      }
    })
  },

  getMostFavoriteProducts: async () => {
    return new Promise(async (resolve, reject) => {
      let url = `${adminUrl}/favorites/most-favorite`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: getHeaders(),
        })
        .then(res => res.json());
        resolve(response);
      } catch (err) {
        reject(err);
      }
    })
  },

  getFavoriteCount: async (productId) => {
    return new Promise(async (resolve, reject) => {
      let url = `${adminPublicUrl}/favorites/count/${productId}`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: getHeaders(),
        })
        .then(res => res.json());
        resolve(response);
      } catch (err) {
        reject(err);
      }
    })
  },

  deleteFavoriteFromUsers: async (productId, userId) => {
    return new Promise(async (resolve, reject) => {
      let url = `${adminUrl}/favorites/product/${productId}`;
      try {
        const response = await fetch(url, {
          method: 'DELETE',
          headers: getHeaders(),
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    })
  }
};

export default dataProvider;
