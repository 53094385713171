import { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Loading, useDataProvider, useCreatePath } from 'react-admin';
import { Button as MUIButton } from '@mui/material';
import { renderStatusChip } from 'src/resources/Products/common';

export const GoToProduct = ({ id }) => {
  const createPath = useCreatePath();
  const productPath = createPath({ resource: 'products', type: 'edit', id });

  return (
    <Fragment>
      <MUIButton
        component={Link}
        variant="contained"
        to={productPath}
      >
        Edit
      </MUIButton>
    </Fragment>
  );
};

const MostFavorite = () => {
  const [mostFavoriteDetailed, setMostFavoriteDetailed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dataProvider = useDataProvider();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch most favorites using dataProvider
        const favorites = await dataProvider.getMostFavoriteProducts();

        // Fetch product details for each favorite
        const detailedFavorites = await Promise.all(
          favorites.map(async (favorite) => {
            try {
              const { data: product } = await dataProvider.getOne('products', {
                id: favorite.productUuid
              });
              return {
                ...favorite,
                ...product
              };
            } catch (err) {
              console.error(`Error fetching product ${favorite.productUuid}:`, err);
              return {
                ...favorite,
                product: { name: 'Product not found', sku: favorite.productSku }
              };
            }
          })
        );

        setMostFavoriteDetailed(detailedFavorites);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dataProvider]);

  if (loading) return <Loading />;
  if (error) return <Typography color="error">{error}</Typography>;

  const handleRemoveClick = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleConfirmRemove = () => {
    if (selectedItem) {
      dataProvider.deleteFavoriteFromUsers(selectedItem.id, selectedItem.userUuid)
        .then(() => {
          setMostFavoriteDetailed(mostFavoriteDetailed.filter(f => f.id !== selectedItem.id));
          setOpenDialog(false);
          setSelectedItem(null);
        });
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>Most Favorited Items</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'black' }}>❤️</TableCell>
              <TableCell sx={{ color: 'black' }}>SKU</TableCell>
              <TableCell sx={{ color: 'black' }}>Product Name</TableCell>
              <TableCell sx={{ color: 'black' }}>Edit</TableCell>
              <TableCell sx={{ color: 'black' }}>Price</TableCell>
              <TableCell sx={{ color: 'black' }}>Sale Price</TableCell>
              <TableCell sx={{ color: 'black' }}>Status</TableCell>
              <TableCell sx={{ color: 'black' }}>In-Store</TableCell>
              <TableCell sx={{ color: 'black' }}>Storage Position</TableCell>
              <TableCell sx={{ color: 'black' }}>Updated At:</TableCell>
              <TableCell sx={{ color: 'black' }}>Published At:</TableCell>
              <TableCell sx={{ color: 'black' }}>Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mostFavoriteDetailed.map((favoriteItem) => (
              <TableRow key={favoriteItem.productUuid}>
                <TableCell>{favoriteItem.count} ❤️</TableCell>
                <TableCell>{favoriteItem.id.substring(0, 8)}</TableCell>
                <TableCell>{favoriteItem.name}</TableCell>
                <TableCell><GoToProduct id={favoriteItem.id} /></TableCell>
                <TableCell>{favoriteItem.price + "€" || 'Loading...'}</TableCell>
                <TableCell>{favoriteItem.salePrice + "€" || 'Loading...'}</TableCell>
                <TableCell>{renderStatusChip(favoriteItem)}</TableCell>
                <TableCell>{favoriteItem.inStoreDisplay ? "✅" : "❌" }</TableCell>
                <TableCell>{favoriteItem.storagePosition || "❌" }</TableCell>
                <TableCell>{favoriteItem.updatedAt || "❌" }</TableCell>
                <TableCell>{favoriteItem.publishedAt || "❌" }</TableCell>
                <TableCell>
                  <MUIButton 
                    color="error" 
                    variant="contained" 
                    onClick={() => handleRemoveClick(favoriteItem)}
                  >
                    Remove
                  </MUIButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove this favorite item from ALL users that have it as favorite?
            This action CAN NOT be undone.
            {selectedItem && (
              <Typography component="div" sx={{ mt: 1, fontWeight: 'bold' }}>
                {selectedItem.name} (SKU: {selectedItem.id.substring(0, 8)})
              </Typography>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <MUIButton onClick={() => setOpenDialog(false)}>
            Cancel
          </MUIButton>
          <MUIButton 
            onClick={handleConfirmRemove} 
            color="error" 
            variant="contained"
          >
            Remove
          </MUIButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default MostFavorite;
