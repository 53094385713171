import React, { useState, Fragment } from 'react';
import {
  AutocompleteInput,
  Button,
  Create,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import {
  RichTextInput,
} from 'ra-input-rich-text';

import { availableGenders } from 'src/utils';
import { generateSlug, LowValueDescriptionButton, DescriptionOptions, PickupIdSelector } from 'src/resources/Products/common';

import styles from 'src/resources/Products/styles.module.scss';

export const ProductCreate = props => {
  const [slug, setSlug] = useState('');

  return (
    <Create {...props} redirect="edit">
      <SimpleForm>
        <TextInput source="name" id="productName" validate={[required()]} fullWidth resettable onChange={(e) => generateSlug(e.target.value, setSlug)} />
        <div className="helper-text">* User-friendly name that will be displayed on the Product Page.</div>
        <div className="helper-text">* Follow format: <b>'Brand name' + 'material' + 'item name' + 'color'</b>. Example: "Adidas jacket blue" or "Lacoste leather hat brown".</div>
        <div className="helper-text">* If no brand available enter <b>No name</b>. Example: "No name blue jacket".</div>
        <div className="helper-text">* For books follow format: <b>'Author name' + 'book title'</b>. Example: "Elliot Whatever - The Blue Sky"</div>
        <LowValueDescriptionButton />
        <div>Proposed slug:(Auto-generated)
          <br />
          <b id='generatedSlug'>{slug}</b>
          {slug !== '' && (
            <Button
              onClick={() => {
                const value = document.getElementById('generatedSlug').textContent;
                navigator.clipboard.writeText(value);
              }}
              label='✂️Copy'
            />
          )}
        </div>
        <TextInput id="slug" source="slug" validate={[required()]} fullWidth resettable />
        <div className="helper-text">* Same as name but <b>NO capital letters, special characters or spaces</b>. Example: "adidas-blue-jacket".</div>
        <PickupIdSelector />
        <div className="helper-text">* Choose the bag with id "1" for items with no seller (Sold by Cirkel).</div>
        <div className="helper-text">* You can search for older Pickup IDs as well.</div>
        <div className={styles.priceContainer}>
          <ReferenceInput
            label="Category"
            source="categoryId"
            reference="categories"
            sort={{ field: 'categoryName', order: 'DESC' }}
            filterToQuery={searchText => ({ categoryName: searchText })}
            alwaysOn
          >
            <AutocompleteInput
              optionText="categoryName"
              validate={[required()]}
              resettable
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return formData?.categoryId && (
                <Fragment>
                  <ReferenceArrayInput
                    label="Sub Categories"
                    source="subcategoriesIds"
                    reference="subcategories"
                    perPage={100}
                    filter={{ categoryId: formData?.categoryId }}
                  >
                    <SelectArrayInput
                      optionText="subcategoryName"
                      validate={[required()]}
                    />
                  </ReferenceArrayInput>
                </Fragment>
              )
            }}
          </FormDataConsumer>
        </div>
        <div className="helper-text">* You must select exactly one Category for any item.</div>
        <div className="helper-text">* You can choose more than one Sub-Categories that belong to the above selected Category.</div>
        <SelectInput label="Gender" source="gender" choices={availableGenders} validate={[required()]} resettable />
        <div className="helper-text">* For books you pick either "Unisex" or "Kid".</div>
        <div className={styles.priceContainer}>
          <NumberInput source="suggestedPrice" defaultValue={0} />
          <NumberInput source="price" defaultValue={0} />
        </div>
        <div className="helper-text">* The price that Cirkel suggests the Sellers to list the item for.</div>
        <div className="helper-text">* The price that the item will go for sale (either with or without the sellers configuration).</div>
        {/* <FormDataConsumer>
          {({ formData, ...rest }) => {
            return formData?.categoryId && (
              <Fragment>
                <SelectInput label="Average weight" validate={[required()]} source="weight" choices={getProductWeightsByCategory(formData?.categoryId)} optionText="label" optionValue="value" />
                <div className="helper-text">* The weight of the product to be used for calculating shipping costs.</div>
              </Fragment>
            );
          }}
        </FormDataConsumer> */}
        <h3>Choose Customer description template:</h3>
        <DescriptionOptions />
        <RichTextInput fullWidth multiline source="description" id='itemDescription' initialValue={''} />
        <div className="helper-text">* This description will be used on the Product page to inform viewers of additional characteristics.</div>
        <div className="helper-text">* Must be in Greek. (English version is optional)</div>
      </SimpleForm>
    </Create>
  )
};
