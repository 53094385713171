import React, { useState, useEffect, Fragment } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  DateTimeInput,
  Edit,
  EditButton,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  useCreatePath,
  useDataProvider,
  useRecordContext,
} from 'react-admin';

import { Link } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LocalLaundryServiceRoundedIcon from '@mui/icons-material/LocalLaundryServiceRounded';
import BuildCircleRoundedIcon from '@mui/icons-material/BuildCircleRounded';
import FeedIcon from '@mui/icons-material/Feed';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import CategoryIcon from '@mui/icons-material/Category';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { Language as OnlineIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Button as MUIButton } from '@mui/material';

import { parseDateWithSeconds } from 'src/utils';
import { CopyProductSKU, LowValueDescriptionButton, DescriptionOptions, PickupIdSelector } from 'src/resources/Products/common';

import {
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  AlignmentButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons
} from 'ra-input-rich-text';

import {
  availableProductStatus,
  availableGenders
} from 'src/utils';

import ProductAttributes from 'src/common/ProductAttributes';
import { generateSlug } from 'src/resources/Products/common';
import styles from 'src/resources/Products/styles.module.scss';
import imageCompression from 'browser-image-compression';

const SellersShare = (props) => {
  const record = useRecordContext(props);
  if (record.bagPickupId < 1786) {
    if (record.salePrice <= 50) {
      return (record.salePrice * 30 / 100).toFixed(2);
    } else {
      return ((50 * 30 / 100) + ((record.salePrice - 50) * 60 / 100)).toFixed(2);
    }
  } else {
    // New share structure from Pickup #1786:
    if (record.salePrice <= 30) {
      return (record.salePrice * 15 / 100).toFixed(2);
    } else  if (record.salePrice > 30 && record.salePrice <= 50) {
      return ((30 * 15 / 100) + (record.salePrice - 30) * 25 / 100).toFixed(2);
    } else {
      return ((30 * 15 / 100) + (20 * 25 / 100) + (record.salePrice - 50) * 35 / 100).toFixed(2);
    }
  }
}

const Seller = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const createPath = useCreatePath();
  const [seller, setSeller] = useState({});

  useEffect(() => {
    (async (record) => {
      let res = '';
      try {
        res = await dataProvider.getOne('pickups', { id: record.bagPickupId });
        res = await dataProvider.getOne('users', { id: res.data.userAddressSnapshot.userId });
      } catch (error) {
        console.warn('Error: ', error);
      }
      setSeller(seller => {
        return res?.data
      })
    })(record)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  const userPath = createPath({ resource: 'users', type: 'edit', id: seller?.id });

  return (
    <table>
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Phone number</th>
          <th>Email Address</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{seller?.firstName}</td>
          <td>{seller?.lastName}</td>
          <td>{seller?.phoneNumber}</td>
          <td>{seller?.email}</td>
          <td>
          <EditButton component={Link} variant="contained" to={userPath} label="Edit" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

const GetImageName = () => {
  const record = useRecordContext();

  return (
    <div className={styles.imageTitle}>
      {record?.data?.substring(record?.data?.lastIndexOf("/") + 1)}
    </div>
  );
};

const ReceiptText = () => {
  const record = useRecordContext();

  return record?.code + " - " + record?.name;
};

const CopyReceiptText = () => {
  const receiptText = ReceiptText();

  return (
    <Button
      style={{ minWidth: '32px' }}
      sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }}}
      onClick={() => navigator.clipboard.writeText(receiptText)}
    >
      <ContentCopyIcon />
    </Button>
  );
};

const EditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
    {/* <DeleteButton /> */}
  </Toolbar>
);

const CopyUniqueProductID = () => {
  const record = useRecordContext();

  return (
    <Button
      style={{ minWidth: '32px' }}
      sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }}}
      onClick={() => navigator.clipboard.writeText(record && record.id)}
    >
      <ContentCopyIcon />
    </Button>
  );
}

const GoToOrder = () => {
  const record = useRecordContext();
  const createPath = useCreatePath();

  if (!record) {
    return null;
  }
  const userPath = createPath({ resource: 'orders', type: 'edit', id: record.orderId });

  return (
    <MUIButton
      component={Link}
      variant="contained"
      to={userPath}
    >
      View Order
    </MUIButton>
  );
};

const GetImageMetadata = () => {
  const record = useRecordContext();
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    if (record?.data) {
      const img = new Image();

      const getBlobSize = async (url) => {
        try {
          // For new files, use the rawFile size
          if (record.rawFile) {
            return (record.rawFile.size / 1024).toFixed(2);
          }

          // For existing images, just show 'Remote file'
          return 'Remote file';
        } catch (error) {
          console.error('Error getting image size:', error);
          return 'Unknown';
        }
      };

      img.onload = async () => {
        const ratio = (img.width / img.height).toFixed(2);
        const isCorrectRatio = Math.abs(parseFloat(ratio) - 0.67) <= 0.02;

        // Get file extension from URL or filename
        const fileName = record.data.substring(record.data.lastIndexOf("/") + 1);
        const fileExtension = fileName.split('.').pop().toLowerCase();
        // Strict check for exactly 'jpeg', not 'jpg'
        const isCorrectExtension = fileExtension === 'jpeg';

        // Get size
        const size = `${await getBlobSize(record.data)} KB`;

        setMetadata({
          width: img.width,
          height: img.height,
          ratio,
          isCorrectRatio,
          extension: fileExtension,
          isCorrectExtension,
          size
        });
      };

      img.onerror = (error) => {
        console.error('Image load error:', error);
        setMetadata({
          width: '?',
          height: '?',
          ratio: '?',
          isCorrectRatio: false,
          extension: '?',
          isCorrectExtension: false,
          size: 'Error loading'
        });
      };

      img.src = record.data;
    }
  }, [record]);

  if (!metadata.width) {
    return <div className={styles.imageMetadata}>Loading metadata...</div>;
  }

  const StatusIndicator = ({ isCorrect }) => (
    <span style={{ color: isCorrect ? '#4caf50' : '#f44336' }}>
      {isCorrect ? '✓' : '✗'}
    </span>
  );

  return (
    <div className={styles.imageMetadata}>
      <div>Dimensions: {metadata.width} x {metadata.height}</div>
      <div>
        Ratio: {metadata.ratio} {' '}
        <StatusIndicator isCorrect={metadata.isCorrectRatio} />
      </div>
      <div>
        Extension: {metadata.extension} {' '}
        <StatusIndicator isCorrect={metadata.isCorrectExtension} />
      </div>
      <div>Size: {metadata.size}</div>
    </div>
  );
};

export const ProductEdit = props => {
  const record = useRecordContext();
  const [slug, setSlug] = useState('');
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [newFilesTableVisible, setNewFilesTableVisible] = useState(false);
  const [compressionOptions, setCompressionOptions] = useState({
    maxIteration: 30,
    initialQuality: 0.85,
    useWebWorker: true,
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1249,
    enableRenaming: true,
    enableCompression: true
  });

  const handleCompressionOptionChange = (option, value) => {
    setCompressionOptions(prev => ({
      ...prev,
      [option]: value
    }));
  };

  const compressImage = async (file) => {
    try {
      console.log(`Before compression: ${(file.size / 1024).toFixed(2)} KB`);

      const options = {
        maxSizeMB: compressionOptions.maxSizeMB,
        maxWidthOrHeight: compressionOptions.maxWidthOrHeight,
        useWebWorker: compressionOptions.useWebWorker,
        maxIteration: compressionOptions.maxIteration,
        initialQuality: compressionOptions.initialQuality,
        alwaysKeepResolution: false,
        fileType: 'image/jpeg',
        signal: null,
      };

      console.log('Compressing image...');
      const compressedFile = await imageCompression(file, options);

      // Rename if needed (jpg to jpeg)
      let fileName = compressedFile.name;
      if (fileName.toLowerCase().endsWith('.jpg')) {
        fileName = fileName.slice(0, -4) + '.jpeg';
      }

      const finalFile = new File([compressedFile], fileName, {
        type: 'image/jpeg',
        lastModified: file.lastModified
      });

      console.log(`After compression: ${(finalFile.size / 1024).toFixed(2)} KB`);
      console.log(`Compression ratio: ${((1 - finalFile.size / file.size) * 100).toFixed(2)}%`);

      return finalFile;
    } catch (error) {
      console.error('Compression failed:', error);
      return file;
    }
  };

  const imageInputOptions = {
    onDrop: () => setNewFilesTableVisible(true),
    onDropAccepted: async (newAcceptedFiles) => {
      // No need to store compressed files, just add the original files
      setAcceptedFiles(acceptedFiles.concat(newAcceptedFiles));
    },
    onDropRejected: (newRejectedFiles) => {
      setRejectedFiles(rejectedFiles.concat(newRejectedFiles));
    },
    accept: {
      'image/jpeg': ['.jpeg', '.jpg']
    },
  };

  const transform = async (data) => {
    setAcceptedFiles([]);
    setRejectedFiles([]);
    setNewFilesTableVisible(false);

    // Deep clone the data to avoid mutating the original
    const newData = { ...data };

    if (newData.images) {
      // Count existing images from the record data
      const existingImagesCount = record?.images?.length || 0;

      // Process each image
      newData.images = await Promise.all(newData.images.map(async (image, index) => {
        // Skip processing for existing images
        if (!image.rawFile) return image;
        let processedFile = image.rawFile;
        // Apply compression if enabled
        if (compressionOptions.enableCompression) {
          processedFile = await compressImage(processedFile);
        }
        // Apply renaming if enabled
        if (compressionOptions.enableRenaming) {
          // For first image (if no images exist), name it 1.jpeg
          // For other images, continue numbering from existing count
          const newName = existingImagesCount === 0 && index === 0
            ? '1.jpeg'
            : `${newData.code}-${existingImagesCount + index + 1}.jpeg`;
          processedFile = new File([processedFile], newName, {
            type: 'image/jpeg',
            lastModified: processedFile.lastModified
          });
        }

        return {
          ...image,
          rawFile: processedFile,
          title: processedFile.name
        };
      }));
    }
    return newData;
  };

  return (
    <Edit {...props} redirect={false} transform={transform}>
      <TabbedForm
        warnWhenUnsavedChanges
        toolbar={<EditToolbar />}
      >
        <FormTab label="Essentials" icon={<FeedIcon />}>
          <div className="helper-text">Unique Product ID::</div>
            <div>
              <CopyUniqueProductID />
              <TextField source="id" />
            </div>
          <div className="helper-text">SKU:</div>
            <div>
              <CopyProductSKU />
              <TextField source="code" />
            </div>
          <div className="helper-text">* Receipt Text (for use in cashier):</div>
          <div>
            <CopyReceiptText />
            <ReceiptText />
          </div>
          <TextInput source="name" id="productName" validate={[required()]} fullWidth onChange={(e) => generateSlug(e.target.value, setSlug)} />
          <div className="helper-text">* User-friendly name that will be displayed on the Product Page.</div>
          <div className="helper-text">* Follow format: <b>'Brand name' + 'material' + 'item name' + 'color'</b>. Example: "Adidas jacket blue" or "Lacoste leather hat brown".</div>
          <div className="helper-text">* If no brand available enter <b>No name</b>. Example: "No name blue jacket".</div>
          <div className="helper-text">* For books follow format: <b>'Author name' + 'book title'</b>. Example: "Elliot Whatever - The Blue Sky"</div>
          <LowValueDescriptionButton />
          <div>Proposed slug: (Auto-generated)
            <br />
            <b id='generatedSlug'>{slug}</b>
            {slug !== '' && (
              <Button
                onClick={() => {
                  const value = document.getElementById('generatedSlug').textContent;
                  navigator.clipboard.writeText(value);
                }}
                label='✂️Copy'
              />
            )}
          </div>
          <TextInput source="slug" fullWidth validate={[required()]} />
          <div className="helper-text">* Same as name but <b>NO capital letters, special characters or spaces</b>. Example: "adidas-blue-jacket".</div>
          <PickupIdSelector />
          <div className="helper-text">* Choose the bag with id "1" for items with no seller (Sold by Cirkel).</div>
          <div className="helper-text">* You can search for older Pickup IDs as well.</div>
          <div className={styles.priceContainer}>
            <NumberInput label={"Price"} source="price" />
            <NumberInput label={"Sale Price"} source="salePrice" />
            <NumberInput label={"Suggester Price"} source="suggestedPrice" />
          </div>
          <div className="helper-text">* Price: The price that Cirkel suggests the Sellers to list the item for.</div>
          <div className="helper-text">* Sale Price: The price that the item will go for sale (either with or without the sellers configuration).</div>
          <div className="helper-text">* Suggester Price: The price that Cirkel may choose to discount the item with.</div>
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <div className={styles.row}>
                    <SelectInput
                      label="Status"
                      source="productStatusName"
                      validate={[required()]}
                      choices={availableProductStatus}
                      // disabled={formData.productStatusName === 'SOLD' && !statusUnlocked}
                    />
                    {formData.productStatusName === 'SOLD' ?
                      formData.orderId ?
                        <span className={styles.row}>
                          <OnlineIcon fontSize="large" /> {"Product sold online!"} <GoToOrder />
                        </span>
                        : "Product sold in store!"
                      : null
                    }
                  </div>
                );
              }}
            </FormDataConsumer>
        </FormTab>
        <FormTab label="Description" icon={<TextIncreaseIcon />}>
          <DescriptionOptions />
          <RichTextInput
            id='itemDescription'
            source="description"
            fullWidth
            toolbar={
              <RichTextInputToolbar>
                <LevelSelect size={'large'} />
                <FormatButtons size={'large'} />
                <AlignmentButtons size={'large'} />
                <ListButtons size={'large'} />
                <LinkButtons size={'large'} />
                <QuoteButtons size={'large'} />
                <ClearButtons size={'large'} />
              </RichTextInputToolbar>
            }
          />
          <div className="helper-text">* This description will be used on the Product page to inform viewers of additional characteristics.</div>
        </FormTab>
        <FormTab label="Categorization" icon={<CategoryIcon />}>
          <ReferenceInput
            label="Category"
            source="categoryId"
            reference="categories"
            sort={{ field: 'categoryName', order: 'ASC' }}
            filterToQuery={searchText => ({ categoryName: searchText })}
            alwaysOn
          >
            <AutocompleteInput optionText="categoryName" validate={[required()]} />
          </ReferenceInput>
          <div className="helper-text">* The category of an item can not be edited after it has been created.</div>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ReferenceArrayInput
                source="subcategoriesIds"
                reference="subcategories"
                filter={{ categoryId: formData && formData.categoryId }}
              >
                <SelectArrayInput optionText="subcategoryName" validate={[required()]} />
              </ReferenceArrayInput>
            )}
          </FormDataConsumer>
          <div className="helper-text">* You can choose more than one Sub-Categories that belong to the above selected Category.</div>
          <SelectInput label="Gender" source="gender" choices={availableGenders} validate={[required()]} />
          <div className="helper-text">* For books you pick either "Unisex" or "Kid".</div>
          <div>
            <BooleanInput label="Featured" source="featured" options={{ checkedIcon: <FavoriteIcon /> }} />
            <BooleanInput label="Last Call" source="lastCall" options={{ checkedIcon: <WatchLaterRoundedIcon /> }} />
          </div>
        </FormTab>
        <FormTab label="Attributes" icon={<EditAttributesIcon />}>
          <FormDataConsumer>
            {({ formData }) => {
              return <ProductAttributes formData={formData} />
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Cleaning" icon={<LocalLaundryServiceIcon />}>
          <BooleanInput label="Cleaned" source="cleaned" options={{ checkedIcon: <LocalLaundryServiceRoundedIcon /> }} />
          <BooleanInput label="Fixed" source="fixed" options={{ checkedIcon: <BuildCircleRoundedIcon /> }} />
        </FormTab>
        <FormTab label="Images" icon={<AddPhotoAlternateIcon />}>
          <Typography variant="h6">Image Processing Options</Typography>
          <div className={styles.compressionToggles}>
            <BooleanInput
              label="Enable File Renaming"
              source="enableRenaming"
              options={{
                checked: compressionOptions.enableRenaming,
                onChange: (e) => handleCompressionOptionChange('enableRenaming', e.target.checked)
              }}
            />
            <BooleanInput
              label="Enable Compression & Resizing"
              source="enableCompression"
              options={{
                checked: compressionOptions.enableCompression,
                onChange: (e) => handleCompressionOptionChange('enableCompression', e.target.checked)
              }}
            />
          </div>

          <Typography variant="h6">Compression Settings</Typography>
          <div className={styles.compressionSettings} style={{
            opacity: compressionOptions.enableCompression ? 1 : 0.5,
            pointerEvents: compressionOptions.enableCompression ? 'auto' : 'none'
          }}>
            <div className={styles.compressionOption}>
              <NumberInput
                source="Max Size (MB)"
                value={compressionOptions.maxSizeMB}
                onChange={(e) => handleCompressionOptionChange('maxSizeMB', parseFloat(e.target.value))}
                min={0.01}
                max={0.5}
                step={0.01}
                defaultValue={0.5}
                className={styles.optionInput}
              />
            </div>
            <div className={styles.compressionOption}>
              <NumberInput
                source="Max Width/Height (px)"
                value={compressionOptions.maxWidthOrHeight}
                onChange={(e) => handleCompressionOptionChange('maxWidthOrHeight', parseInt(e.target.value))}
                min={500}
                max={2000}
                step={1}
                defaultValue={1249}
                className={styles.optionInput}
              />
            </div>
            <div className={styles.compressionOption}>
              <NumberInput
                source="Max Iterations"
                value={compressionOptions.maxIteration}
                onChange={(e) => handleCompressionOptionChange('maxIteration', parseInt(e.target.value))}
                min={1}
                max={100}
                defaultValue={30}
                className={styles.optionInput}
              />
            </div>
            <div className={styles.compressionOption}>
              <NumberInput
                source="Initial Quality (0-1)"
                value={compressionOptions.initialQuality}
                onChange={(e) => handleCompressionOptionChange('initialQuality', parseFloat(e.target.value))}
                min={0}
                max={1}
                step={0.05}
                defaultValue={0.85}
                className={styles.optionInput}
              />
            </div>
            <div className={styles.webWorkerOption}>
              <BooleanInput
                label="Use Web Worker"
                source="useWebWorker"
                options={{
                  checked: compressionOptions.useWebWorker,
                  onChange: (e) => handleCompressionOptionChange('useWebWorker', e.target.checked)
                }}
              />
            </div>
          </div>
          <Typography variant="h6">Add the Featured image here!</Typography>
          <ImageInput
            source="images"
            accept="image/jpeg"
            multiple
            maxSize={500000}
            options={imageInputOptions}
          >
            <Fragment>
              <GetImageName />
              <GetImageMetadata />
              <ImageField sx={{ '& .RaImageField-image': { width: 335, height: 500, objectFit: 'contain' } }} source="data" />
            </Fragment>
          </ImageInput>
          <div className="helper-text">* Only .jpeg (not .jpg)</div>
          <div className="helper-text">** Maximum allowed size 500KB</div>
          <div className="helper-text">*** Featured image must be named 1.jpeg</div>
          <div className="helper-text">**** Recommended image ratio 0.67. This means all of the above resolutions:</div>
          <div className="helper-text">• Width x Height (in pixels)</div>
          <div className="helper-text">• 670 x 1000</div>
          <div className="helper-text">• 1005 x 1500</div>
          <div className="helper-text">• 1340 x 2000</div>
          {newFilesTableVisible &&
            <table className={styles.fileTable}>
              <colgroup span="7"></colgroup>
              <tbody>
                <tr key={"header"}>
                  <th>#</th>
                  <th>Status</th>
                  <th>File name</th>
                  <th>Size (KB)</th>
                  <th>Rejection Reason</th>
                  <th>Type</th>
                  <th>Last Modified</th>
                </tr>
              </tbody>
              {acceptedFiles.map((acceptedFile, index) => {
                return (
                  <tbody key={`accepted-${index}`}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>🟢</td>
                      <td>{acceptedFile.name}</td>
                      <td>{acceptedFile.size}</td>
                      <td> - - </td>
                      <td>{acceptedFile.type}</td>
                      <td>{acceptedFile.lastModified}</td>
                    </tr>
                  </tbody>
                );
              })}
              {rejectedFiles.map((rejectedFile, index) => {
                return (
                  <tbody key={`rejected-${index}`}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>🔴</td>
                      <td>{rejectedFile.file.name}</td>
                      <td>{rejectedFile.size}</td>
                      <td>{rejectedFile.errors.map((error, errorIndex) => 
                        <li key={`error-${index}-${errorIndex}`}>{error.message}</li>
                      )}</td>
                      <td>{rejectedFile.file.type}</td>
                      <td>{rejectedFile.lastModified}</td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          }
        </FormTab>
        <FormTab label="Administration" icon={<DisplaySettingsIcon />}>
          {/* <SelectInput label="Average weight" source="weight" choices={productWeights} optionText="label" optionValue="value" />*/}
          <TextInput label='Storage Position' source='storagePosition' />
          <NumberInput label='Quantity' source="quantity" defaultValue={1} />
          <div className="helper-text">* Only for stack of items that will go for donation or recycling!</div>
          <div className="helper-text">* Leave at 1 if item will go for sale.</div>
          <RichTextInput
          id='itemStatusDescription'
          source="statusDescription"
          multiline="true"
          fullWidth
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect size={'large'} />
              <FormatButtons size={'large'} />
              <AlignmentButtons size={'large'} />
              <ListButtons size={'large'} />
              <LinkButtons size={'large'} />
              <QuoteButtons size={'large'} />
              <ClearButtons size={'large'} />
            </RichTextInputToolbar>
          }
        />
          <div className="helper-text">* This info is for internal communication only and will not be visible on the site</div>
          <DateTimeInput label='Creation Date:' source='createdAt' locales="el-GR" disabled parse={parseDateWithSeconds} />
          <div className="helper-text">* This is the date the item was created in the system.</div>
          <DateTimeInput label='Last Update Date:' source='updatedAt' locales="el-GR" disabled parse={parseDateWithSeconds} />
          <div className="helper-text">* This is the date the last date someone made an edit to this item.</div>
          <FormDataConsumer>
            {({ formData }) => (
              <DateTimeInput
                label='Publish Date:'
                source='publishedAt'
                locales="el-GR"
                parse={parseDateWithSeconds}
                validate={formData.productStatusName === 'SOLD' ? [required()] : undefined}
              />
            )}
          </FormDataConsumer>
          <div className="helper-text">* This is the first time the item went live for sale on our website.</div>
          <DateTimeInput label='Expiration Date:' source="expirationDate" locales="el-GR" parse={parseDateWithSeconds} />
          <DateTimeInput label='Sold At:' source='soldAt' locales="el-GR" parse={parseDateWithSeconds} />
          <div className="helper-text">* This is the the time the item SOLD.</div>
          <BooleanInput label={"Discount Applied"} source={"discountApplied"} disabled />
          <div className="helper-text">* Whether this product was bought with a promotional code.</div>
        </FormTab>
        <FormTab label="Seller" icon={<PermContactCalendarIcon />}>
          <div>Sellers share: <strong><SellersShare /> Euro</strong></div>
          <BooleanInput label="Seller Share Paid" source="sellerSharePaid" options={{ checkedIcon: <PaidRoundedIcon /> }} />
          <Seller />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
};
